import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValientesService {

  private valientes=[]

  constructor() { }

  agregarValiente(nombre:string, apellido:string){
    this.valientes.push({
      nombre,
      apellido,
      id: this.valientes.length + 1 + "",
      letras: 0,
      punto: 0,
      ronda: 1,
      ganador: 0
    });
  }
  borrarValiente(valienteId:string){
      this.valientes = this.valientes.filter(valiente => {
      return valiente.id != valienteId
    })
  }
  obtenerValiente(){
    return [...this.valientes]
  }

  obtenerValientes(valienteId: string){
    return {
      ...this.valientes.find(valiente => {
      return valiente.id === valienteId
     })
    }
  }

  
}
