import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ValientesService } from '../valientes.service';

@Component({
  selector: 'app-nuevo-valiente',
  templateUrl: './nuevo-valiente.page.html',
  styleUrls: ['./nuevo-valiente.page.scss'],
})
export class NuevoValientePage implements OnInit {

  numeros : ['0-9']

  constructor(private valientesService: ValientesService, private router: Router, public alertController: AlertController) {}

  ngOnInit() {
  }
  
  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'PELIGRO!!!',
      message: 'Ingrese un nombre.',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('no ingreso un nombre', role);
  }

  guardarNuevoValiente(nombre,apellido){
    if(nombre.value!='' || apellido.value !=''){
            this.valientesService.agregarValiente(nombre.value,apellido.value);
            this.router.navigate(['/valientes']);
            nombre.value='';
            apellido.value='';
     }
     else{
       this.presentAlert();
     }
  }
}
