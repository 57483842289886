import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NuevoValientePageRoutingModule } from './nuevo-valiente-routing.module';

import { NuevoValientePage } from './nuevo-valiente.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NuevoValientePageRoutingModule
  ],
  declarations: [NuevoValientePage]
})
export class NuevoValientePageModule {}
